import IMAGE_FRAGMENT from '../image'

const PACKAGES_FRAGMENT = `
...on pageSegments_packages_Entry {
  id
  anchorId
  typeHandle
  mainTitle
  subtitle
  bodyText
  packageEntries {
    ...on packages_default_Entry {
      id
      slug
      highlight
      packageName
      packageDescription
      price
      pricePostfix
      originalPrice
      originalPriceLabel
      discountHighlight
      buttonLink
      buttonLabel
      moreInfoLink
      moreInfoLabel
      bulletPoints {
        ...on bulletPoints_TableRow {
          highlight
          text
        }
      }
      packageAdditional {
        ...on packageAdditional_text_BlockType {
          id
          typeHandle
          text
        }
        ...on packageAdditional_textHighlight_BlockType {
          id
          typeHandle
          text
        }
        ...on packageAdditional_bullets_BlockType {
          id
          typeHandle
          bullets {
            ...on bullets_TableRow {
              highlight
              text
            }
          }
        }
        ...on packageAdditional_partnerLogo_BlockType {
          id
          typeHandle
          partnerLabel
          partnerLogo {
            ${ IMAGE_FRAGMENT }
          }
        }
      }
    }
  }
  packages {
    ...on packages_BlockType {
      id
      highlight
      packageTitle
      description
      price
      pricePostfix
      originalPrice
      originalPriceLabel
      buttonLink
      buttonLabel
      moreInfoLink
      moreInfoLabel
      bullets {
        ...on bullets_TableRow {
          highlight
          text
        }
      }
      additional {
        ...on additional_text_BlockType {
          id
          typeHandle
          text
        }
        ...on additional_textHighlight_BlockType {
          id
          typeHandle
          text
        }
        ...on additional_bullets_BlockType {
          id
          typeHandle
          bullets {
            ...on bullets_TableRow {
              highlight
              text
            }
          }
        }
        ...on additional_partnerLogo_BlockType {
          id
          typeHandle
          partnerLabel
          logo {
            ${ IMAGE_FRAGMENT }
          }
        }
      }
    }
  }
}
`

export default PACKAGES_FRAGMENT
